<template>
  <div class="ExchangeSelect">
    <FormSelect
      v-bind="$attrs"
      v-model="model"
      label="Exchange"
      placeholder="— Select an Exchange —"
      field-key="key"
      text-field="name"
      :options="exchanges"
      @change="onChange"
    />
  </div>
</template>

<script>
  import FormSelect from '@/components/base/FormSelect'

  import { mapState } from 'vuex'

  export default {
    components: {
      FormSelect
    },

    props: {
      modelValue: {
        type: String,
        default: null
      }
    },

    emits: [
      'update:modelValue'
    ],

    data() {
      return {
        model: this.modelValue
      }
    },

    computed: {
      ...mapState('exchanges', [ 'exchanges' ])
    },

    methods: {
      onChange() {
        this.$emit('update:modelValue', this.model)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'
</style>s
