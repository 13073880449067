<template>
  <div class="modal-header">
    <h5 class="modal-title">
      <span v-if="allocation.id">Edit an Allocation</span>
      <span v-if="!allocation.id">Add an Allocation</span>
    </h5>

    <button type="button" class="btn-close" @click="modal.close()" />
  </div>

  <div class="modal-body">
    <div class="alert alert-danger" v-if="formError">
      {{ formError }}
    </div>

    <form novalidate class="form">
      <div class="mb-3 form-required">
        <FormInput label="Allocation name" :validator="v$.form.name" />
      </div>

      <div class="mb-3 form-required">
        <ExchangeSelect :validator="v$.form.exchange" />
      </div>

      <fieldset>
        <div class="row">
          <div class="col-md-6">
            <div class="mb-3 form-required">
              <FormInput label="Inception balance" type="number" step="any" :validator="v$.form.inception_balance" />
            </div>
          </div>

          <div class="col-md-6">
            <div class="mb-3 form-required">
              <FormInput label="Inception date" type="date" :validator="v$.form.inception_date" />
            </div>
          </div>
        </div>
      </fieldset>

      <button type="button" class="btn btn-sm btn-outline-secondary mb-3" @click="toggleApiInfoEdition" v-if="allocation.id">
        <span v-if="!apiInfoEdited">Open</span>
        <span v-if="apiInfoEdited">Cancel</span>
        Api key and Secret edition
      </button>

      <input type="hidden" name="username">

      <div class="mb-3 form-required" v-if="canEditApi()">
        <FormInput label="Api key" :validator="v$.form.api" autocomplete="api-key" />
      </div>

      <div class="mb-3 form-required" v-if="canEditApi()">
        <FormPassword label="Api Secret" :validator="v$.form.secret" autocomplete="new-password" />
      </div>

      <div class="mb-3 form-required" v-if="canEditApi() && canEditApiExtra()">
        <FormInput :label="selectedExchange.api_extra_param" :validator="v$.form.api_extra_param" />
      </div>
    </form>
  </div>

  <div class="modal-footer d-flex">
    <button type="button" class="btn btn-danger me-auto" @click="remove(allocation)" v-if="allocation.id" :disabled="v$.$dirty">
      Remove
    </button>

    <button type="button" class="btn btn-outline-primary" @click="modal.close()">
      Cancel
    </button>

    <button type="button" class="btn btn-primary" @click="save" :disabled="v$.$invalid">
      Save
    </button>
  </div>
</template>

<script>
  import ModalConfirm from '@/components/ModalConfirm'
  import FormInput from '@/components/base/FormInput'
  import FormPassword from '@/components/base/FormPassword'
  import ExchangeSelect from '@/components/ExchangeSelect'

  import allocationService from '@/services/allocations.service'

  import useVuelidate from '@vuelidate/core'
  import useModal from '@/plugins/modal/useModal'
  import { required, requiredIf, decimal } from '@vuelidate/validators'
  import { formatDate } from '@/utils/date'
  import { mapState } from 'vuex'
  import { omit } from 'lodash'

  export default {
    components: {
      FormInput,
      FormPassword,
      ExchangeSelect
    },

    props: {
      allocation: {
        type: Object,
        default: () => ({})
      },
      modal: {
        type: Object,
        default: () => ({})
      }
    },

    setup() {
      return {
        v$: useVuelidate(),
        modal$: useModal()
      }
    },

    data() {
      return {
        form: {
          name: '',
          exchange: null,
          inception_balance: null,
          inception_date: formatDate(new Date(), 'yyyy-MM-dd'),
          api: null,
          secret: null
        },
        formError: '',
        apiInfoEdited: false
      }
    },

    computed: {
      ...mapState('exchanges', [ 'exchanges' ]),

      selectedExchange() {
        return this.form.exchange && this.exchanges && this.exchanges[this.form.exchange]
      }
    },

    watch: {
      allocation: {
        immediate: true,
        handler(allocation) {
          this.form = { ...this.form, ...allocation }
          this.form.inception_date = formatDate(this.form.inception_date, 'yyyy-MM-dd')
        }
      }
    },

    validations() {
      return {
        form: {
          exchange: { required },
          name: { required },
          api: {
            required: requiredIf(() => this.canEditApi())
          },
          secret: {
            required: requiredIf(() => this.canEditApi())
          },
          api_extra_param: {
            required: requiredIf(() => this.canEditApi() && this.canEditApiExtra())
          },
          inception_balance: { required, decimal },
          inception_date: { required }
        }
      }
    },

    methods: {
      toggleApiInfoEdition() {
        this.apiInfoEdited = !this.apiInfoEdited
      },

      canEditApi() {
        return this.selectedExchange && (!this.allocation.id || this.apiInfoEdited)
      },

      canEditApiExtra() {
        return this.canEditApi() && this.selectedExchange.api_extra_param
      },

      remove() {
        this.formError = ''

        this.modal$
          .open(ModalConfirm, {
            type: 'danger'
          })
          .onClose(async (confirm) => {
            if (confirm) {
              try {
                await allocationService.delete(this.form.id)
                this.modal.close('DELETED')
              } catch (e) {
                this.formError = 'Something went wrong on the server when deleting: ' + e.data.message
              }
            }
          })
      },

      async save() {
        this.formError = ''

        let payload = this.form

        if (payload.id && !this.apiInfoEdited) {
          payload = omit(this.form, [ 'api', 'secret' ])
        }

        try {
          await allocationService.save(payload)
          this.modal.close('SAVED')
        } catch (e) {
          this.formError = 'Something went wrong on the server: ' + e.data.message
        }
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import '@/sass/styles.vars.all.sass'

  fieldset
    border: 1px solid $gray-300
    padding: $spacer $spacer 0
    margin-bottom: $spacer
    background: $gray-100
</style>

